import React from 'react'
import Fullscreen from 'components/Fullscreen'
const sources = [
  { type: 'video/webm', src: `/video.webm` },
  { type: 'video/mp4; codecs="hvc1"', src: `/video.hevc.mp4` },
  { type: 'video/mp4; codec="avc1"', src: `/video.mp4` },
]

function usePrevious(value) {
  const ref = React.useRef()
  React.useEffect(() => {
    ref.current = value
  })
  return ref.current
}

// if (typeof window !== 'undefined') {
//   Object.defineProperty(HTMLMediaElement.prototype, 'playing', {
//     get: function () {
//       return !!(
//         this.currentTime > 0 &&
//         !this.paused &&
//         !this.ended &&
//         this.readyState > 2
//       )
//     },
//   })
// }

export default function Video({ onEnded, active, interval = 1000, ...rest }) {
  const [ended, setEnded] = React.useState(false)

  const handleEnded = e => {
    setEnded(true)
    typeof onEnded === 'function' && onEnded(e)
  }

  const [fading, setFading] = React.useState(false)

  const previousActive = usePrevious(active)

  React.useEffect(() => {
    if (!active && previousActive) {
      setFading(true)
      const id = setTimeout(() => setFading(false), interval)
      return () => clearTimeout(id)
    }
  }, [active])

  React.useEffect(() => {
    if (!active || ended) return
    const timeout = setTimeout(handleEnded, 17500)
    return () => clearTimeout(timeout)
  }, [active])

  const ref = React.useRef(null)

  React.useEffect(() => {
    if (active && ref?.current?.paused === true && !ended) ref?.current?.play()
  }, [active, ref, ended])

  // if (!active && !fading) return null

  return (
    <Fullscreen
      zIndex="7"
      style={{
        padding: 24,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        pointerEvents: 'none',
      }}
    >
      <video
        controls={false}
        playsInline
        muted
        autoPlay
        // data-wf-ignore="true"
        // data-object-fit="cover"
        {...rest}
        onEnded={handleEnded}
        style={{
          width: '100%',
          transition: 'all 1s',
          position: 'relative',
          ...(active
            ? { bottom: 0, opacity: 1 }
            : { bottom: '-100vw', opacity: 0 }),
        }}
        ref={ref}
      >
        {sources.map(source => (
          <source key={source.src} {...source} />
        ))}
      </video>
    </Fullscreen>
  )
}
