import { Canvas } from '@react-three/fiber'
// import bear from 'bear.glb'
// import * as THREE from 'three'
// import Basic from 'components/models/Basic'
import BasicRobijn from 'components/models/BasicRobijn'
import BasicRobijn1k from 'components/models/BasicRobijn1k'
import React, { Suspense } from 'react'
// import Stacy from 'components/models/Stacy'
// import Bearcy from 'components/models/Bearcy'
// import Bear from 'components/models/Bear'

// import Video1 from 'components/video/Video1'
// import Video2 from 'components/video/Video2'
// import CameraControls from './CameraControls'

function Scene(props) {
  const { playing } = props
  return (
    <>
      <Canvas
        mode="concurrent"
        // orthographic
        colorManagement={false}
        camera={{ position: [0, 0.2, 2.3] }}
        shadowMap
        style={{
          zIndex: 1,
          transition: 'opacity 0.5s',
          opacity: playing ? 1 : 0,
        }}
      >
        <ambientLight intensity={0.34} color={0xf5e7eb} />
        <directionalLight
          intensity={0.4}
          position={[2, 0.5, 2]}
          color={0xf5e7eb}
        />

        {/* <spotLight intensity={0.8} position={[-2, 0.5, 1]} /> */}
        {/* <hemisphereLight
          skyColor={'black'}
          groundColor={0xffffff}
          intensity={0.5}
          position={[0, 50, 0]}
        /> */}

        <Suspense fallback={null}>
          {/* {window.location.search.includes('1k') ? ( */}
          <BasicRobijn1k position={[0, -0.3, 1.3]} {...props} />
          {/* ) : ( */}
          {/* <BasicRobijn position={[0, -0.3, 1.3]} {...props} /> */}
          {/* )} */}
        </Suspense>
      </Canvas>
    </>
  )
}

// ReactDOM.render(<App />, document.getElementById('root'))

export default Scene

// 1. camera permissions
// 2. try audio
// 3. is the bear loaded
