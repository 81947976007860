const styles = {
  textTransform: 'uppercase',
  color: '#fff',
  borderRadius: 4,
  padding: '8px 16px',
  background: '#E1251B',
  border: 0,
  fontStyle: 'normal',
  fontWeight: 'bold',
  fontSize: '19px',
  lineHeight: '25px',
}

const disabledStyles = {
  color: '#E1251B',
  background: 'rgba(255, 255, 255, 0.74)',
}

const sizeLargeStyles = {}

export default function Button({
  disabled,
  style: propStyle = {},
  size,
  ...rest
}) {
  const style = {
    ...styles,
    ...(disabled && disabledStyles),
    ...(size === 'large' && sizeLargeStyles),
    ...propStyle,
  }
  return <button disabled={disabled} {...rest} style={style} />
}
