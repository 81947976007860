import React from 'react'
import getGeoData from './getGeoData'
import { useTrack } from './TrackingProvider'

const useTrackGeo = () => {
  const track = useTrack()

  React.useEffect(() => {
    const sendGeoData = async () => {
      const data = await getGeoData()
      track('geolocation', { ...data, nonInteraction: 1 })
    }
    sendGeoData()
  }, [])
}

export default useTrackGeo
