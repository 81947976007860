import './FlowerPane.css'
import Fullscreen from 'components/Fullscreen'
import Button from 'components/ui/Button'

export default function FlowerPane({ cameraStarting, onClick, zIndex = 3 }) {
  const opacity = cameraStarting ? 0 : 1
  return (
    <Fullscreen
      zIndex={zIndex}
      // background="transparent"
      // background="rgba(255,200,200,0.3)"
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        // background: [
        //   'white linear-gradient(180deg, rgba(236, 119, 0, 0) 29.47%, rgba(236, 119, 0, 0.51) 88.41%)',
        //   'url(/bear.png) left 50px / 80% auto no-repeat',
        // ].join(', '),
        backgroundColor: 'white',
        background:
          'linear-gradient(180deg, rgba(236, 119, 0, 0) 29.47%, rgba(236, 119, 0, 0.51) 88.41%), transparent url(/bear.png) no-repeat left 50px / 80%',
        // backgroundColor: 'transparent',
        // backgroundSize: '80%',
        // backgroundRepeat: 'no-repeat',
        // backgroundPosition: 'left 50px',
        // backgroundImage: 'url(/bear.png)',
      }}
    >
      <div
        style={{
          backgroundImage: 'url(/speech-bubble.png)',
          backgroundSize: 'contain',
          position: 'absolute',
          backgroundRepeat: 'no-repeat',
          top: '17%',
          left: '52%',
          opacity,
          width: 125,
          height: 82,
        }}
      />
      <div
        style={{ backgroundImage: 'url(/flowers-orange.png)', opacity }}
        className="FlowerOrange"
      />
      <div className="FlowerPane" style={{ opacity }}>
        <p>We willen je kennis laten maken met</p>
        <h3 style={{ textTransform: 'uppercase', fontSize: 29 }}>
          onze Robijn beer
        </h3>
        <p>
          en je laten zien hoe je langer van je <br />
          kleding geniet
        </p>
        <Button
          onClick={onClick}
          style={{ padding: 8 }}
          data-user-action="enter experience"
        >
          <img src="/icon-eye.svg" />
          &nbsp;Sta toegang tot camera toe
        </Button>
      </div>
      <div
        style={{ backgroundImage: 'url(/flowers-pink.png)', opacity }}
        className="FlowerPink"
      />
    </Fullscreen>
  )
}
