// import LogRocket from 'utils/LogRocket'
// import reportWebVitals from './reportWebVitals';
import { AdobeTagsProvider } from 'components/tracking/AdobeTagsProvider'
import { TrackingProvider } from 'components/tracking/TrackingProvider'
import TrackPassive from 'components/tracking/TrackPassive'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import './index.css'
ReactDOM.render(
  <React.StrictMode>
    <AdobeTagsProvider
      brandCategory="Home Care"
      country="Netherlands"
      globalBrand="Comfort"
      localBrand="Robijn"
      trackingId="UA-8422581-1,G-V5RNP7MGHM"
      reportSuiteId="unilever-robijn-snuggle-nl,unilever-global-allbrands"
      internalDomain="ar.robijndoetdewas.nl"
      pageName="Robijn Home"
      scriptSrc="//assets.adobedtm.com/e6bd1902389a/2932680d9594/launch-700e4ca0526f.min.js"
    >
      {/* <SegmentSnippet /> ON DOCUMENT */}
      <TrackingProvider>
        <App />
        <TrackPassive />
      </TrackingProvider>
    </AdobeTagsProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
