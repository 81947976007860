// import logo from './logo.svg';
// import './App.css';
import React, { Suspense } from 'react'
import CameraView from 'components/CameraView'
import Fullscreen from 'components/Fullscreen'

function CameraViewWithPermissions({
  onPlay,
  onError,
  zIndex = 3,
  track = () => {},
  children = ({ cameraStarting, onClick }) => (
    <button
      onClick={onClick}
      // style={{ zIndex: 3 }}
    >
      {cameraStarting ? 'Camera starting...' : 'Start camera'}
    </button>
  ),
}) {
  const [cameraStarting, setCameraStarting] = React.useState(null)
  const [cameraStarted, setCameraStarted] = React.useState(null)
  const [cameraFailed, setCameraFailed] = React.useState(null)

  // const handleClick = async () => {
  //   try {
  //     await audioRef.current.play()
  //     setPlaying(true)
  //   } catch (e) {
  //     alert(e)
  //   }
  // }
  // const handleTest = async () => {
  //   try {
  //     await audioRef.current.play()
  //   } catch (e) {
  //     alert(e)
  //   }
  // }

  const handleCameraStarted = () => {
    track('click', { action: 'camerastarted', nonInteration: 1 })
    setCameraStarted(true)
    typeof onPlay === 'function' && onPlay()
  }

  const handleCameraFailed = () => {
    track('click', { action: 'camerafailed', nonInteration: 1 })
    setCameraFailed(true)
    typeof onError === 'function' && onError()
  }

  return (
    <>
      {!cameraStarted &&
        !cameraFailed &&
        children({ cameraStarting, onClick: () => setCameraStarting(true) })}
      {(cameraStarting || cameraStarted) && (
        <CameraView
          // onCanPlay={handleCameraStarted}
          onPlay={handleCameraStarted}
          onError={handleCameraFailed}
        />
      )}
      {cameraFailed && (
        <Fullscreen zIndex={0} background="rgba(255,200,200,1)">
          failed
        </Fullscreen>
      )}
    </>
  )

  // return interacted ? (
  //   <Scene />
  // ) : (
  //   <button onClick={() => setInteracted(true)}>Do interaction</button>
  // )
}

export default CameraViewWithPermissions
